module.exports=`
<link rel="stylesheet"
      href="/langings/agileEcosystem/css/tilda-grid-3.0.min.css" type="text/css"
      media="all">
<link rel="stylesheet" href="/langings/agileEcosystem/css/tilda-blocks-2.14.css"
      type="text/css" media="all">
<link rel="stylesheet"
      href="/langings/agileEcosystem/css/tilda-slds-1.4.min.css" type="text/css"
      media="all" onload="this.media=&#39;all&#39;;">
<link rel="stylesheet"
      href="/langings/agileEcosystem/css/tilda-zoom-2.0.min.css" type="text/css"
      media="all" onload="this.media=&#39;all&#39;;">
<noscript>
  <link rel="stylesheet" href="/langings/agileEcosystem/css/tilda-zoom-2.0.min.css" type="text/css" media="all"/>
  <link rel="stylesheet" href="/langings/agileEcosystem/css/tilda-slds-1.4.min.css" type="text/css" media="all"/>
</noscript>
<link rel="stylesheet"
      href="/langings/agileEcosystem/css/tilda-cover-1.0.min.css"
      type="text/css" media="all">

<script src="/langings/agileEcosystem/js/jquery-1.10.2.min.js"
        type="text/javascript"></script>
<script src="/langings/agileEcosystem/js/tilda-scripts-3.0.min.js"
        type="text/javascript"></script>
<script src="/langings/agileEcosystem/js/tilda-blocks-2.7.js"
        type="text/javascript"></script>
<script async="" src="/langings/agileEcosystem/js/lazyload-1.3.min.js"
        charset="utf-8" type="text/javascript"></script>

<script type="text/javascript">window.dataLayer = window.dataLayer || [];</script>
<style type="text/css">@media screen and (min-width: 980px) {
  .t-records {
    opacity: 0;
  }

  .t-records_animated {
    -webkit-transition: opacity ease-in-out .2s;
    -moz-transition: opacity ease-in-out .2s;
    -o-transition: opacity ease-in-out .2s;
    transition: opacity ease-in-out .2s;
  }

  .t-records.t-records_visible {
    opacity: 1;
  }
}</style>
`
